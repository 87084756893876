.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.title-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}

.title-4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
}

.body-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.body-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.button-m {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}
