#wheel4p {
  overflow: hidden;
  position: relative;

  .button-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15vw;
    height: 15vw;
    max-width: 85px;
    max-height: 85px;
    border: none;
    border-radius: 100px;
    background: transparent;
    box-shadow: none;
    color: white;
    padding: 0;
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.6655px;
    white-space: pre-wrap;

    .button {
      width: 100%;
      height: auto;
    }
  }
}
