@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "./styles/bootstrap-grid.min.css";
@import "./styles/buttons";
@import "./styles/form";

html,
body,
#root {
  overflow: auto;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-loading {
  min-height: 100vh;
}
