@import "../../../styles/variables";

#wheel-page {
  &.no-spinning {
    background: url("../../../assets/images/cark_light_bg.png") no-repeat center;
    background-size: cover;
  }

  .page-content {
    .wheel-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .meta-logo {
      margin-bottom: 16px;
    }

    .prize-title {
      color: $primary-color;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 46px;
    }

    .wheel-wrapper {
      opacity: 1;

      &.hidden {
        opacity: 0;
      }
    }
  }

  @media (min-width: 576px) {
    &.no-spinning {
      background-image: url("../../../assets/images/cark_light_bg_desktop.png");
    }
  }
}
