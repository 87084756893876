@import "../../../styles/variables";

#home-page {
  justify-content: center;

  .page-content {
    background: url("../../../assets/images/home.svg") no-repeat center;
    background-size: cover;
    justify-content: flex-end;

    @media (min-width: 576px) {
      background-image: url("../../../assets/images/home_desktop.png");
    }
  }
}
