@import "variables";

.app-button.ant-btn {
  background: $primary-color;
  color: $black;
  border: none;
  box-shadow: 0 4px 8px rgba(28, 37, 44, 0.08);
  border-radius: 14px;
  height: 48px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  $spacing: 16px;
  margin: $spacing;
  width: calc(100% - #{2 * $spacing});
  max-width: 400px;

  &:hover {
    opacity: 0.85;
    color: $black;
  }

  span {
    @extend .button-m;
  }

  @media (min-width: 576px) {
    margin-bottom: 7%;
  }
}

.app-link-button.ant-btn {
  @extend .app-button;
  background: none;
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}
