@import "../../../styles/variables";

#app-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $black;
  background: url("../../../assets/images/cark_bg.svg") no-repeat center;
  background-size: cover;

  .page {
    display: flex;
    flex-grow: 1;

    .page-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 576px) {
    background-image: url("../../../assets/images/cark_bg_desktop.png");
  }
}
