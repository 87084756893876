@import "../../styles/variables";

.modal {
  max-width: 315px;

  &.ant-modal {
    .ant-modal-content {
      border-radius: 22px;
      overflow: hidden;
      background: $black;
      padding: 0;

      .ant-modal-body {
        padding: 32px 16px 0;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .image {
            width: 112px;
            height: 128px;
          }

          .title {
            @extend .title-4;
            color: $white;
            margin: 24px 0 0;
          }

          .text {
            @extend .body-1;
            color: $white;
            margin: 8px 0 0;
          }

          .info {
            @extend .body-2;
            color: $primary-color;
            margin: 8px 0 24px;
          }

          .app-form {
            margin-top: 24px;
          }

          .button-wrapper {
            width: 100%;
            margin-top: 24px;
          }

          .app-button,
          .app-link-button {
            margin: 0 0 16px;
            width: 100%;
          }
        }
      }
    }
  }
}
