@import "variables";

.app-form {
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px $dark inset !important;
    -webkit-text-fill-color: $white !important;
    background-color: $dark !important;
  }

  .ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    color: $white;
    background: $dark;
    border: none;
    box-shadow: none;
    height: auto;
    border-radius: 14px;
    padding: 11px 16px;
    font-size: 17px;
    line-height: 22px;
    caret-color: $primary-color;
    transition: none;

    &::placeholder {
      color: $placeholder;
    }

    &:hover,
    &:focus,
    &:active {
      background: $dark;
      border: none;
      color: $white;
      box-shadow: none;
    }
  }

  .ant-form-item-explain-error {
    color: $red;
    text-align: left;
    padding: 0 16px;
  }
}
